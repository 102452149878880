import { Injectable } from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  constructor(private cookieService: CookieService, private http: HttpClient) { }


  getUserData() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser' });
    const options = { headers: headers };

    const data =

      {
        "AccessToken": this.cookieService.get("AccessToken", )
      }

    return this.http.post<any>('https://cognito-idp.eu-central-1.amazonaws.com', data, options);
  }

  updateProfile(userData: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-amz-json-1.1',
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.UpdateUserAttributes' });
    const options = { headers: headers };

    const data =

      {
        "AccessToken": this.cookieService.get("AccessToken"),
        "UserAttributes": [
          {
            "Name": "family_name",
            "Value": userData.lastName
          },
          {
            "Name": "given_name",
            "Value": userData.firstName
          },
          {
            "Name": "phone_number",
            "Value": userData.phone
          },
          {
            "Name": "custom:custom:newsltrOptIn",
            "Value": userData.newsltrOptIn?"True":"False"
          },
        ]
      }

    return this.http.post<any>('https://cognito-idp.eu-central-1.amazonaws.com', data, options);
  }
}
