import { Component } from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-decision-modal',
  standalone: true,
  imports: [],
  templateUrl: './decision-modal.component.html',
  styleUrl: './decision-modal.component.css'
})
export class DecisionModalComponent {
  modalTitle: string;
  message: string;

  constructor(private modalService: NgbModal,public activeModal: NgbActiveModal) {
    this.modalTitle = 'modalTitle';
    this.message = 'message';
  }

  no() {
    this.activeModal.close(false);
  }

  yes() {
    this.activeModal.close(true);
  }
}
