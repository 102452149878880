<app-dashboard class="page-title-overlap bg-accent pt-4" title="{{dashboardTitle}}"></app-dashboard>
<div class="container mb-5 pb-3">
  <div class="rounded-3 overflow-hidden">
    <div class="row">
      <app-sidebar class="col-lg-4 pt-4 pt-lg-0 pe-xl-5"></app-sidebar>
      <!-- Content-->
      <section class="col-lg-8">
        <!-- Profile form-->
        <form>
          <div class="bg-secondary rounded-3 p-4 mb-4">
            <div class="d-flex align-items-center"><img class="rounded" [src]="userData.picture" width="90" alt="userData.firstName userData.lastName">
              <!--
              <div class="ps-3">
                <button class="btn btn-light btn-shadow btn-sm mb-2" type="button"><i class="ci-loading me-2"></i>Change avatar</button>
                <div class="p mb-0 fs-ms text-muted">Upload JPG, GIF or PNG image. 300 x 300 required.</div>
              </div>
              -->
            </div>
          </div>
          <div class="row gx-4 gy-3">
            <div class="col-sm-6">
              <label class="form-label" for="account-fn">Prenume</label>
              <input class="form-control" type="text" id="account-fn" name="firstName" [(ngModel)]="userData.firstName">
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="account-ln">Nume</label>
              <input class="form-control" type="text" id="account-ln" name="lastName" [(ngModel)]="userData.lastName">
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="account-email">Adresa de email</label>
              <input class="form-control" type="email" id="account-email" name="email" [(ngModel)]="userData.email" disabled="">
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="account-phone">Telefon (sa inceapa cu +40..)</label>
              <input class="form-control" type="text" id="account-phone" name="phone" [(ngModel)]="userData.phone" required="">
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="account-pass">Parola noua</label>
              <div class="password-toggle">
                <input class="form-control" type="password" id="account-pass">
                <label class="password-toggle-btn" aria-label="Show/hide password">
                  <input class="password-toggle-check" type="checkbox"><span class="password-toggle-indicator"></span>
                </label>
              </div>
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="account-confirm-pass">Confirmare parola</label>
              <div class="password-toggle">
                <input class="form-control" type="password" id="account-confirm-pass">
                <label class="password-toggle-btn" aria-label="Show/hide password">
                  <input class="password-toggle-check" type="checkbox"><span class="password-toggle-indicator"></span>
                </label>
              </div>
            </div>

            <div class="col-12">
              <hr class="mt-2 mb-3">
              <div class="alert alert-danger" *ngIf="showErrorMessage">
                <strong>Eroare!</strong> {{errorMessage}}.
              </div>

              <div class="d-flex flex-wrap justify-content-between align-items-center">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="subscribe_me" name="account-newsltrOptIn" [(ngModel)]="userData.newsltrOptIn" >
                  <label class="form-check-label" for="subscribe_me">Vreau sa ma abonez la Newsletter (Afla cand venim cu idei noi!)</label>
                </div>
                <button class="btn btn-primary mt-3 mt-sm-0" type="button" (click)="updateProfile()">Salveaza datele</button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
</div>

