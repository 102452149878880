import {Component, OnInit} from '@angular/core';
import {CognitoService} from "../../service/cognito.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MessageModalComponent} from "../../common/modals/message-modal/message-modal.component";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {DashboardComponent} from "../../common/dashboard/dashboard.component";

import { parsePhoneNumberFromString } from 'libphonenumber-js';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [FormsModule, NgIf, ReactiveFormsModule, SidebarComponent, DashboardComponent],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.css'
})
export class ProfileComponent implements OnInit{
  cognitoUserData: any;
  userData: any;

  showErrorMessage: boolean;
  errorMessage: any;
  dashboardTitle: string;

  constructor(private cognitoService: CognitoService, private modalService: NgbModal) {
    this.showErrorMessage = false;
    this.dashboardTitle = 'Profilul tau Corporatristi.ro'
  }

  ngOnInit(): void {
    this.userData = {};
    this.cognitoService.getUserData().subscribe(response => {
      this.cognitoUserData = response;
      this.parseUserData();
    })
  }

  private parseUserData() {

    for(let userAttribute of this.cognitoUserData.UserAttributes) {
      if(userAttribute.Name == 'email') {
        this.userData.email = userAttribute.Value;
      } else if(userAttribute.Name == 'picture') {
        this.userData.picture = userAttribute.Value;
      } else if(userAttribute.Name == 'family_name') {
        this.userData.lastName = userAttribute.Value;
      } else if(userAttribute.Name == 'given_name') {
        this.userData.firstName = userAttribute.Value;
      } else if(userAttribute.Name == 'phone_number') {
        this.userData.phone = userAttribute.Value;
      } else if(userAttribute.Name == 'custom:custom:newsltrOptIn') {
        this.userData.newsltrOptIn = userAttribute.Value === 'True';
      }
    }

    if(!this.userData.picture) {
      this.userData.picture = '/assets/img/account/avatar.png';
    }
  }

  updateProfile(): void {
    this.showErrorMessage = false;


    if (!this.userData.firstName || !this.userData.lastName || !this.userData.phone) {
      this.showErrorMessage = true;
      this.errorMessage = 'Numele, prenumele si numarul de telefon sunt obligatorii!';
      return;
    }

    const phoneNumber = parsePhoneNumberFromString(this.userData.phone);

    if (phoneNumber && !phoneNumber.isValid()) {
      this.showErrorMessage = true;
      this.errorMessage = 'Numarul de telefon este invalid. Trebuie sa fie de forma +40723123456!';
      return;
    }

      this.cognitoService.updateProfile(this.userData).subscribe((response: any) => {
        let messageModal = this.modalService.open(MessageModalComponent);
        messageModal.componentInstance.modalTitle = 'Succes';
        messageModal.componentInstance.message = "Datele au fost actualizate cu succes!";

      }, error => {
        this.errorMessage = "A avut loc o eroare la salvarea datelor!";
        this.showErrorMessage = true;
      })
  }
}
