
<div class="modal-header">
  <h5 class="modal-title">Add a new address</h5>
  <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="row gx-4 gy-3">
    <div class="col-sm-12">
      <label class="form-label" for="address-name">Nume adresa</label>
      <input class="form-control" type="text" id="address-name" required name="name" [(ngModel)]="address.addressName">
    </div>
    <div class="col-sm-12">
      <label class="form-label" for="address-name">Nume persoana contact</label>
      <input class="form-control" type="text" id="contact-name" required name="name" [(ngModel)]="address.contactName">
    </div>
    <div class="col-sm-12">
      <label class="form-label" for="address-name">Telefon persoana</label>
      <input class="form-control" type="text" id="address-phone" required name="phone" [(ngModel)]="address.phone">
    </div>

    <div class="col-sm-6">
      <label class="form-label" for="address-address">Adresa</label>
      <input class="form-control" type="text" id="address-line" required name="address" [(ngModel)]="address.addressLine">
    </div>

    <div class="col-sm-6">
      <label class="form-label" for="address-city">City</label>
      <input class="form-control" type="text" id="address-city" required  name="city" [(ngModel)]="address.city">
    </div>

    <div class="col-sm-6">
      <label class="form-label" for="address-city">County</label>
      <input class="form-control" type="text" id="address-county" required  name="county" [(ngModel)]="address.county">
    </div>

    <div class="col-sm-6">
      <label class="form-label" for="address-country">Country</label>
      <select class="form-select" id="address-country" required  name="country"  [(ngModel)]="address.country">
        <option value>Select country</option>
        <option value="Romania">Romania</option>
        <option value="Bulgaria">Bulgaria</option>
        <option value="Hungary">Hungary</option>
      </select>
    </div>

    <div class="col-12">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="address-default"  name="default"  [(ngModel)]="address.default">
        <label class="form-check-label" for="address-default">Make this address primary</label>
      </div>
    </div>

    <hr class="mt-2 mb-3">
    <div class="alert alert-danger" *ngIf="showErrorMessage">
      <strong>Eroare!</strong> {{errorMessage}}.
    </div>

  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-secondary" type="button" data-bs-dismiss="modal" (click)="close()">Inchide</button>
  <button class="btn btn-primary btn-shadow" type="submit" (click)="addNewAddress()">{{operation == 'add'?'Adauga adresa':'Modifica adresa'}}</button>
</div>

