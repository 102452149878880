import { Component } from '@angular/core';
import {environment} from "../../../environments/environment";
import {FooterStateService} from "../../service/footer-state.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [NgIf],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {

  protected readonly environment = environment;
  showMyAccountButton: boolean = true;

  constructor(private footerStateService: FooterStateService) {}

  ngOnInit() {
    // Subscribe to the observable to get the current button visibility state
    this.footerStateService.showMyAccountButton$.subscribe(show => {
      this.showMyAccountButton = show;
    });
  }
}
