import {ApplicationConfig, ErrorHandler} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";
import {GlobalErrorHandler} from "./service/global-error-handler";
import {HttpRequestInterceptor} from "./service/http-request-interceptor";

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    provideRouter(routes), provideHttpClient(withInterceptorsFromDi()),
  provideAnimations(),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
    ]
};
