import {Component, OnInit} from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {SessionStateService} from "../../service/session-state.service";

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.css'
})
export class SidebarComponent implements OnInit{

  userData: any;

  constructor(protected sessionStateService:SessionStateService) {
  }

  ngOnInit(): void {


    this.userData = this.sessionStateService.getUserData();

    if(!this.userData) {
      this.userData = {};
    }
    if(!this.userData.picture) {
      this.userData.picture = '/assets/img/account/avatar.png';
    }
  }
  signOut() {
    this.sessionStateService.signOut();
  }
}
