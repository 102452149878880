import {Component, OnInit} from '@angular/core';
import {DashboardComponent} from "../common/dashboard/dashboard.component";
import {environment} from "../../environments/environment";
import {AccountApiService} from "../service/account-api.service";
import {DecisionModalComponent} from "../common/modals/decision-modal/decision-modal.component";
import {MessageModalComponent} from "../common/modals/message-modal/message-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CookieService} from "ngx-cookie-service";
import {CommonModule, LowerCasePipe, NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-shopping-cart',
  standalone: true,
  imports: [
    DashboardComponent, NgForOf, NgIf, LowerCasePipe, CommonModule, FormsModule
  ],
  templateUrl: './shopping-cart.component.html',
  styleUrl: './shopping-cart.component.css'
})
export class ShoppingCartComponent implements OnInit{
  shoppingCart:any;
  dashboardTitle:string;
  constructor(private accountApiService: AccountApiService, private modalService: NgbModal, private cookieService: CookieService) {
    this.dashboardTitle = "Cosul de cumparaturi";
    this.shoppingCart = {products:[],count:0, total:0};
  }

  ngOnInit(): void {
    this.accountApiService.getShoppingCart().subscribe(response => {
      if(response != null) {
        if(response.products != null) {
          this.shoppingCart = response;
        }
      }

    }, error => {
      if(error.status == 404) {
        this.shoppingCart =  {products:[],count:0, total:0};
      } else {
        throw error;
      }
    });
  }



  deleteProduct(product:any) {
    let ngbModalRef = this.modalService.open(DecisionModalComponent);
    ngbModalRef.componentInstance.modalTitle = 'Stergere produs';
    ngbModalRef.componentInstance.message = 'Esti sigur ca vrei sa stergi produsul ' + product.name + '?';
    ngbModalRef.result.then(result => {
      if(result) {
        this.shoppingCart.products.splice(this.shoppingCart.products.indexOf(product), 1);

        this.cookieService.set('cart', JSON.stringify(this.shoppingCart), 24 * 30,'/', environment.cookieDomain);

        this.accountApiService.overwriteShoppingCart(this.shoppingCart).subscribe(response => {
          let messageModal = this.modalService.open(MessageModalComponent);
          messageModal.componentInstance.modalTitle = 'Succes';
          messageModal.componentInstance.message = 'Produsul a fost sters cu succes!';
        });
      }
    });

  }


  protected readonly environment = environment;


  updateShoppingCart() {

    this.accountApiService.overwriteShoppingCart(this.shoppingCart).subscribe(response => {

      this.shoppingCart = response;
      this.cookieService.set('cart', JSON.stringify(this.shoppingCart), 24 * 30,'/', environment.cookieDomain);

      let messageModal = this.modalService.open(MessageModalComponent);
      messageModal.componentInstance.modalTitle = 'Succes';
      messageModal.componentInstance.message = 'Cosul a fost actualizat!';
    });
  }
}
