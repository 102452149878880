import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root' // This makes the service available application-wide
})
export class FooterStateService {
  // Use a BehaviorSubject to store the visibility state of footer buttons
  private showMyAccountButtonSubject = new BehaviorSubject<boolean>(true);

  // Observable to subscribe to the visibility state
  showMyAccountButton$ = this.showMyAccountButtonSubject.asObservable();

  // Method to update the visibility of the button
  setShowMyAccountButton(show: boolean) {
    this.showMyAccountButtonSubject.next(show);
  }
}
