import {Component} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-message-modal',
  standalone: true,
  imports: [
  ],
  templateUrl: './message-modal.component.html',
  styleUrl: './message-modal.component.css'
})
export class MessageModalComponent {
  modalTitle: string;
  message: string;

  constructor(private modalService: NgbModal) {
    this.modalTitle = 'modalTitle';
    this.message = 'message';
  }

  close() {
    this.modalService.dismissAll();
  }

}
