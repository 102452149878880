
<!-- Navbar Marketplace-->
<!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page.-->
<header class="bg-light shadow-sm navbar-sticky">
  <!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page.-->
  <div class="navbar-sticky bg-light">
    <div class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <a class="navbar-brand d-none d-sm-block flex-shrink-0" href="/index.html"><img src="assets/img/logo-dark.png" width="142" alt="Corporatristi.ro"></a>
        <a class="navbar-brand d-sm-none flex-shrink-0 me-2" href="/index.html"><img src="assets/img/logo-icon.png" width="74" alt="Corporatristi.ro"></a>

        <div class="d-none d-lg-flex flex-lg-fill mx-4">
          <a class="nav-link left me-auto" href="{{environment.siteBaseURL}}"><i class="navbar-tool-icon ci-store"></i> Inapoi la Produse</a>
        </div>
        <div class="d-sm-none flex-fill mx-4">
          <a class="nav-link right me-auto" href="{{environment.siteBaseURL}}"><i class="navbar-tool-icon ci-store float-end"></i></a>
        </div>

        <div class="navbar-toolbar d-flex flex-shrink-0 align-items-center">


          <a class="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" id="userMyAccountLink" href="https://login.dev.corporatristi.ro/login?client_id=1r9t9q158589h4kcg468vgd7cv&response_type=token&redirect_uri=https://dev.corporatristi.ro">
            <div class="navbar-tool-icon-box"><i class="navbar-tool-icon ci-user"></i></div>
            <div class="navbar-tool-text ms-n3" id="userMyAccountText"><small>Salut, Intra in</small>Contul tau</div>
          </a>

          <div id="cartHeader"  class="navbar-tool dropdown ms-3">
            <a class="navbar-tool-icon-box bg-secondary dropdown-toggle" href="/shopping-cart">
              <span class="navbar-tool-label count"></span><i class="navbar-tool-icon ci-cart"></i></a>
            <a class="navbar-tool-text total" href="/shopping-cart"></a>
            <!-- Cart dropdown-->
            <div class="dropdown-menu dropdown-menu-end">
              <div class="widget widget-cart px-3 pt-2 pb-3" style="width: 20rem;">
                <div id="cartDropdownProductListContainer" style="height: 15rem;" data-simplebar data-simplebar-auto-hide="false">

                </div>
                <div class="d-flex flex-wrap justify-content-between align-items-center py-3">
                  <div class="fs-sm me-2 py-2"><span class="text-muted">Subtotal:</span><span class="text-accent fs-base ms-1"  id="cartDropdownSubtotalContainer">0.<small>00</small> Lei</span></div><a class="btn btn-outline-secondary btn-sm" href="/shopping-cart">Vezi detaliat<i class="ci-arrow-right ms-1 me-n1"></i></a>
                </div><a class="btn btn-primary btn-sm d-block w-100" href="checkout-details.html"><i class="ci-card me-2 fs-base align-middle"></i>Comanda</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</header>
