<app-dashboard class="page-title-overlap bg-accent pt-4" title="{{dashboardTitle}}"></app-dashboard>
<div class="container mb-5 pb-3">
  <div class="rounded-3 overflow-hidden">
    <div class="row">
      <app-sidebar class="col-lg-4 pt-4 pt-lg-0 pe-xl-5" ></app-sidebar>
      <!-- Content  -->
      <section class="col-lg-8">

        <!-- Toolbar-->
        <div class="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
          <h6 class="fs-base text-light mb-0">Lista ta de dorinte:</h6>
        </div>

        <p *ngIf="wishlist.products.length == 0"><i>Niciun produs adaugat la Wishlist</i></p>
        <div class="d-sm-flex justify-content-between mt-lg-4 mb-4 pb-3 pb-sm-2 border-bottom" *ngFor="let product of wishlist.products">
          <div class="d-block d-sm-flex align-items-start text-center text-sm-start"><a class="d-block flex-shrink-0 mx-auto me-sm-4" href="/{{product.slug}}.html" style="width: 10rem;"><img src="{{environment.siteBaseURL}}/img/product/{{product.sku | lowercase}}.png" alt="{{product.name}}"></a>
            <div class="pt-2">
              <h3 class="product-title fs-base mb-2"><a href="/{{product.slug}}.html">{{product.name}}</a></h3>
              <div class="fs-sm"><span class="text-muted me-2">Brand:</span>Tommy Hilfiger</div>
              <div class="fs-sm"><span class="text-muted me-2">Color:</span>Khaki</div>
              <div class="fs-lg text-accent pt-2">{{product.price}}.<small>00</small> Lei</div>
            </div>
          </div>
          <div class="pt-2 ps-sm-3 mx-auto mx-sm-0 text-center">
            <button class="btn btn-outline-danger btn-sm" type="button" (click)="deleteProduct(product)"><i class="ci-trash me-2"></i>Sterge</button>
          </div>
        </div>


      </section>
    </div>
  </div>
</div>


