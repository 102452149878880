<!doctype html>
<html lang="ro">
<head>
  <meta charset="utf-8">

  <!-- Viewport-->
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <!-- Favicon and Touch Icons-->
  <link rel="apple-touch-icon" sizes="180x180" href="assets/apple-touch-icon.png">
  <link rel="icon" type="image/png" sizes="32x32" href="assets/favicon-32x32.png">
  <link rel="icon" type="image/png" sizes="16x16" href="assets/favicon-16x16.png">
  <link rel="manifest" href="site.webmanifest">
  <link rel="mask-icon" color="#fe6a6a" href="assets/safari-pinned-tab.svg">
  <meta name="msapplication-TileColor" content="#ffffff">
  <meta name="theme-color" content="#ffffff">
  <!-- Vendor Styles including: Font Icons, Plugins, etc.-->
  <link rel="stylesheet" media="screen" href="/assets/vendor/simplebar/dist/simplebar.min.css"/>
  <link rel="stylesheet" media="screen" href="/assets/vendor/tiny-slider/dist/tiny-slider.css"/>
  <!-- Main Theme Styles + Bootstrap-->
  <link rel="stylesheet" media="screen" href="/assets/css/theme.min.css">
</head>
<!-- Body-->
<body class="handheld-toolbar-enabled">

<app-header></app-header>

<!-- Loading spinner -->
<div *ngIf="loading" class="loading-container flex-content-center">
  <div class="spinner-border text-primary" style="margin:auto" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>


<main class="page-wrapper">
  <router-outlet></router-outlet>

</main>

<app-footer></app-footer>
