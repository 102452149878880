<app-dashboard class="page-title-overlap bg-accent pt-4" title="{{dashboardTitle}}"></app-dashboard>
<div class="container mb-5 pb-3">
  <div class="rounded-3 overflow-hidden">
    <div class="row">
      <app-sidebar class="col-lg-4 pt-4 pt-lg-0 pe-xl-5" ></app-sidebar>
      <!-- Content  -->
      <section class="col-lg-8">
        <!-- Toolbar-->
        <div class="d-none d-lg-flex justify-content-between align-items-center pt-lg-3 pb-4 pb-lg-5 mb-lg-3">
          <h6 class="fs-base text-light mb-0">Adresele tale de livrare:</h6>
        </div>

        <!-- Addresses list-->
        <div class="table-responsive fs-md">
          <table class="table table-hover mb-0">
            <thead  *ngIf="addresses.length > 0">
            <tr>
              <th>Adresa</th>
              <th>Actiuni</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let address of addresses">
              <td class="py-3 align-middle"><b>{{address.addressName}}</b> ( {{address.contactName}} - {{address.phone}}) - {{address.addressLine}}, {{address.city}}, {{address.county}}, {{address.country  }} <span *ngIf="address.default" class="align-middle badge bg-info ms-2">Primary</span></td>
              <td class="py-3 align-middle">
                <a class="nav-link-style me-2" href="#" data-bs-toggle="tooltip" title="Edit" (click)="addUpdateShippingAddress(address)"><i class="ci-edit"></i></a>
                <a class="nav-link-style text-danger" href="#" (click)="deleteAddress(address)" data-bs-toggle="tooltip" title="Sterge">
                  <div class="ci-trash"></div></a>
              </td>
            </tr>
            <i *ngIf="addresses.length == 0">Nicio adresa definita</i>

            </tbody>
          </table>
        </div>
        <div class="text-sm-end pt-4"><a class="btn btn-primary" (click)="addUpdateShippingAddress(null)" data-bs-toggle="modal">Adauga o noua Adresa de livrare</a></div>
      </section>
    </div>
  </div>
</div>


