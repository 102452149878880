import { Component } from '@angular/core';
import {FormsModule} from "@angular/forms";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-add-edit-shipping-address-modal',
  standalone: true,
  imports: [
    FormsModule,
    NgIf
  ],
  templateUrl: './add-edit-shipping-address-modal.component.html',
  styleUrl: './add-edit-shipping-address-modal.component.css'
})
export class AddEditShippingAddressModalComponent {
  address: any;
  showErrorMessage: boolean;
  errorMessage: string;
  operation: string;

  constructor(public activeModal: NgbActiveModal) {
    this.address = {default: false};
    this.showErrorMessage = false;
    this.errorMessage = '';
    this.operation = 'add';

  }

  close() {
    this.activeModal.close();
  }

  addNewAddress() {
    this.showErrorMessage = false;

    if (!this.address || !this.address.addressName || !this.address.contactName || !this.address.phone || !this.address.addressLine || !this.address.city|| !this.address.country) {
      this.showErrorMessage = true;
      this.errorMessage = 'All the input fields are mandatory';
      return;
    }
    this.activeModal.close(this.address);

  }
}
