import {Component, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";

import {DashboardComponent} from "../common/dashboard/dashboard.component";
import {LowerCasePipe, NgForOf, NgIf} from "@angular/common";
import {SidebarComponent} from "../common/sidebar/sidebar.component";
import {AccountApiService} from "../service/account-api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  AddEditShippingAddressModalComponent
} from "../account-profile/add-edit-shipping-address-modal/add-edit-shipping-address-modal.component";
import {MessageModalComponent} from "../common/modals/message-modal/message-modal.component";
import {DecisionModalComponent} from "../common/modals/decision-modal/decision-modal.component";

@Component({
  selector: 'app-wishlist',
  standalone: true,
  imports: [DashboardComponent, NgForOf, NgIf, SidebarComponent, LowerCasePipe],
  templateUrl: './wishlist.component.html',
  styleUrl: './wishlist.component.css'
})
export class WishlistComponent  implements OnInit {

  protected readonly environment = environment;

  wishlist: any;
  dashboardTitle: string;
  constructor(private accountApiService: AccountApiService, private modalService: NgbModal) {
    this.wishlist = {products:[],count:0};
    this.dashboardTitle = "Wishlist";
  }
  ngOnInit(): void {
    this.accountApiService.getWishlist().subscribe(response => {
      if(response != null) {
        if(response.products != null) {
          this.wishlist = response;
        }
      }

    }, error => {
      if(error.status == 404) {
        this.wishlist =  {products:[],count:0};
      } else {
        throw error;
      }
    });
  }



  deleteProduct(product:any) {
    let ngbModalRef = this.modalService.open(DecisionModalComponent);
    ngbModalRef.componentInstance.modalTitle = 'Stergere produs';
    ngbModalRef.componentInstance.message = 'Esti sigur ca vrei sa stergi produsul ' + product.name + '?';
    ngbModalRef.result.then(result => {
      if(result) {
        this.wishlist.products.splice(this.wishlist.products.indexOf(product), 1);

        this.accountApiService.saveWishlist(this.wishlist).subscribe(response => {
          let messageModal = this.modalService.open(MessageModalComponent);
          messageModal.componentInstance.modalTitle = 'Succes';
          messageModal.componentInstance.message = 'Produsul a fost sters cu succes!';
        });
      }
    });

  }

}
