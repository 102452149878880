import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {DashboardComponent} from "./common/dashboard/dashboard.component";
import {HeaderComponent} from "./common/header/header.component";
import {SidebarComponent} from "./common/sidebar/sidebar.component";
import {FooterComponent} from "./common/footer/footer.component";
import {LoadingService} from "./service/loading-service";
import {delay} from "rxjs";
import { CommonModule } from '@angular/common';
import {FooterStateService} from "./service/footer-state.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, DashboardComponent, HeaderComponent, SidebarComponent, FooterComponent, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'account-website';
  loading: boolean = false;

  constructor(
    private _loading: LoadingService, private router: Router, private footerStateService: FooterStateService
  ){ }

  ngOnInit() {
    this.listenToLoading();

    // Subscribe to router events to track route changes
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is 'profile' and update footer state
        if (event.urlAfterRedirects.includes('/shopping-cart') || event.urlAfterRedirects.includes('/checkout')) {
          // Don't show 'My Account' button on profile page
          this.footerStateService.setShowMyAccountButton(true);
        } else {
          // Show 'My Account' button on other pages
          this.footerStateService.setShowMyAccountButton(false);
        }
      }
    });
  }


  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
}
