export const environment = {
    production: false,
    cognitoClientPoolId: '31tr5m8i86l6mnva5jhth52khs',
  accountApiBaseEndpoint: 'https://80gqwss3t8.execute-api.eu-central-1.amazonaws.com/dev/',
  siteBaseURL: 'https://dev.corporatristi.ro',
  accountSiteBaseURL: 'https://account.dev.corporatristi.ro',
  cookieDomain:'.corporatristi.ro',
  loginURL: 'https://login.dev.corporatristi.ro/login?client_id=1r9t9q158589h4kcg468vgd7cv&response_type=token&redirect_uri=https://account.dev.corporatristi.ro',
  name: 'dev'
};
