import {Component, OnInit} from '@angular/core';
import {AdminApiService} from "../../service/admin-api.service";
import {AccountApiService} from "../../service/account-api.service";
import {NgForOf, NgIf} from "@angular/common";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  AddEditShippingAddressModalComponent
} from "../add-edit-shipping-address-modal/add-edit-shipping-address-modal.component";
import {MessageModalComponent} from "../../common/modals/message-modal/message-modal.component";
import {DecisionModalComponent} from "../../common/modals/decision-modal/decision-modal.component";
import {SidebarComponent} from "../../common/sidebar/sidebar.component";
import {DashboardComponent} from "../../common/dashboard/dashboard.component";

@Component({
  selector: 'app-shipping-addresses',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    AddEditShippingAddressModalComponent,
    SidebarComponent,
    DashboardComponent
  ],
  templateUrl: './shipping-addresses.component.html',
  styleUrl: './shipping-addresses.component.css'
})
export class ShippingAddressesComponent  implements OnInit{

  addresses: any;
  dashboardTitle: string;
  constructor(private accountApiService: AccountApiService, private modalService: NgbModal) {
    this.addresses = [];
    this.dashboardTitle = "Adresele tale de livrare";
  }
  ngOnInit(): void {
    this.accountApiService.getShippingAddresses().subscribe(response => {
      if(response) {
        this.addresses = response.addresses;
      }
    }, error => {
      if(error.status == 404) {
        this.addresses = [];
      } else {
        throw error;
      }
    });
  }

  addUpdateShippingAddress(address:any) {
    const operation = address?'update':'add';
    let ngbModalRef = this.modalService.open(AddEditShippingAddressModalComponent);
    ngbModalRef.componentInstance.operation = operation;
    if(address) {
      ngbModalRef.componentInstance.address = address;
    }
    ngbModalRef.result.then(newAddress => {
      if(newAddress) {
        if(newAddress.default) {
          this.addresses.map((e:any) => e.default = false);
          newAddress.default = true;
        }
        if(operation == 'add') {
          if(this.addresses.length == 0) {
            newAddress.default = true;
          }
          this.addresses.push(newAddress);
        }
        this.accountApiService.saveShippingAddresses(this.addresses).subscribe(response => {
          let messageModal = this.modalService.open(MessageModalComponent);
          messageModal.componentInstance.modalTitle = 'Succes';
          messageModal.componentInstance.message = operation == 'add'?'Noua adresa a fost adaugata cu succes!':'Adresa a fost actualizata!';
        });
      }
    });

  }


  deleteAddress(address:any) {
    let ngbModalRef = this.modalService.open(DecisionModalComponent);
    ngbModalRef.componentInstance.modalTitle = 'Stergere adresa';
    ngbModalRef.componentInstance.message = 'Esti sigur ca vrei sa stergi adresa ' + address.addressName + '?';
    ngbModalRef.result.then(result => {
      if(result) {
        this.addresses.splice(this.addresses.indexOf(address), 1);
        if(address.default && this.addresses.length > 0) {
          this.addresses[0].default = true;
        }
        this.accountApiService.saveShippingAddresses(this.addresses).subscribe(response => {
          let messageModal = this.modalService.open(MessageModalComponent);
          messageModal.componentInstance.modalTitle = 'Succes';
          messageModal.componentInstance.message = 'Adresa a fost stearsa cu succes!';
        });
      }
    });

  }
}
