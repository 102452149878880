
<div class="modal-header">
  <h5 class="modal-title">{{modalTitle}}</h5>
  <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"  (click)="close()"></button>
</div>
<div class="modal-body">
  <div class="row gx-4 gy-3">
    <div class="col-sm-12">
      {{message}}
    </div>

  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary btn-shadow" type="submit" (click)="close()">Ok</button>
</div>

