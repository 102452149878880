<!-- Footer-->
<footer class="footer bg-dark pt-5">
  <div class="container">
    <div class="row pb-2">
      <div class="col-md-4 col-sm-6">
        <div class="widget widget-links widget-light pb-2 mb-4">
          <h3 class="widget-title text-light">Categorii</h3>
          <ul class="widget-list">
            <li class="widget-list-item"><a class="widget-list-link" href="/tricouri.html">Tricouri</a></li>
            <li class="widget-list-item"><a class="widget-list-link" href="/maiouri.html">Maiouri</a></li>
            <li class="widget-list-item"><a class="widget-list-link" href="/sepci.html">Sepci</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 col-sm-6">
        <div class="widget widget-links widget-light pb-2 mb-4">
          <h3 class="widget-title text-light">Access rapid</h3>
          <ul class="widget-list">
            <li class="widget-list-item"><a class="widget-list-link" href="#">Contul tau</a></li>
            <li class="widget-list-item"><a class="widget-list-link" href="#">Cosul tau</a></li>
          </ul>
        </div>

      </div>
      <div class="col-md-4">
        <div class="widget pb-2 mb-4">
          <h3 class="widget-title text-light pb-1">Aboneaza-te la Newsletter</h3>
          <form class="subscription-form validate" action="https://studio.us12.list-manage.com/subscribe/post?u=c7103e2c981361a6639545bd5&amp;amp;id=29ca296126" method="post" name="mc-embedded-subscribe-form" target="_blank" novalidate>
            <div class="input-group flex-nowrap"><i class="ci-mail position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>
              <input class="form-control rounded-start" type="email" name="EMAIL" placeholder="Adresa de email" required>
              <button class="btn btn-primary" type="submit" name="subscribe">Aboneaza-te*</button>
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;" aria-hidden="true">
              <input class="subscription-form-antispam" type="text" name="b_c7103e2c981361a6639545bd5_29ca296126" tabindex="-1">
            </div>
            <div class="form-text text-light opacity-50">*Aboneaza-te la newsletter-ul nostru pentru a primi oferte de reduceri timpurii, actualizari si informatii despre produse noi.</div>
            <div class="subscription-status"></div>
          </form>
        </div>

      </div>
    </div>
  </div>
  <div class="pt-5 bg-darker">
    <div class="container">
      <div class="row pb-3">
        <div class="col-md-3 col-sm-6 mb-4">
          <div class="d-flex"><i class="ci-rocket text-primary" style="font-size: 2.25rem;"></i>
            <div class="ps-3">
              <h6 class="fs-base text-light mb-1">Livrare gratuita si rapida</h6>
              <p class="mb-0 fs-ms text-light opacity-50">Livrare gratuita pentru toate comenzile peste 200 lei</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 mb-4">
          <div class="d-flex"><i class="ci-currency-exchange text-primary" style="font-size: 2.25rem;"></i>
            <div class="ps-3">
              <h6 class="fs-base text-light mb-1">Banii inapoi garantat</h6>
              <p class="mb-0 fs-ms text-light opacity-50">Va returnam banii in maxim 30 de zile pentru orice produs</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 mb-4">
          <div class="d-flex"><i class="ci-support text-primary" style="font-size: 2.25rem;"></i>
            <div class="ps-3">
              <h6 class="fs-base text-light mb-1">Support 24/7</h6>
              <p class="mb-0 fs-ms text-light opacity-50">Suntem disponibili pe WhatsApp si Chat 24/7</p>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 mb-4">
          <div class="d-flex"><i class="ci-card text-primary" style="font-size: 2.25rem;"></i>
            <div class="ps-3">
              <h6 class="fs-base text-light mb-1">Plata online securizata</h6>
              <p class="mb-0 fs-ms text-light opacity-50">Integrare cu Netopia pentru o plata online sigura si rapida</p>
            </div>
          </div>
        </div>
      </div>
      <hr class="hr-light mb-5">
      <div class="row pb-2">
        <div class="col-md-6 text-center text-md-start mb-4">
          <div class="text-nowrap mb-4"><a class="d-inline-block align-middle mt-n1 me-3" href="#"><img class="d-block" src="assets/img/footer-logo-light.png" width="117" alt="Corporatrsiti.ro"></a>

          </div>
          <div class="widget widget-links widget-light">
            <ul class="widget-list d-flex flex-wrap justify-content-center justify-content-md-start">
              <li class="widget-list-item me-4"><a class="widget-list-link" href="#">Despre noi</a></li>
              <li class="widget-list-item me-4"><a class="widget-list-link" href="#">Retur</a></li>
              <li class="widget-list-item me-4"><a class="widget-list-link" href="#">Support</a></li>
              <li class="widget-list-item me-4"><a class="widget-list-link" href="#">Privacy</a></li>
              <li class="widget-list-item me-4"><a class="widget-list-link" href="#">Terms of use</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 text-center text-md-end mb-4">
          <div class="mb-3"><a class="btn-social bs-light bs-twitter ms-2 mb-2" href="#"><i class="ci-twitter"></i></a><a class="btn-social bs-light bs-facebook ms-2 mb-2" href="#"><i class="ci-facebook"></i></a><a class="btn-social bs-light bs-instagram ms-2 mb-2" href="#"><i class="ci-instagram"></i></a><a class="btn-social bs-light bs-pinterest ms-2 mb-2" href="#"><i class="ci-pinterest"></i></a><a class="btn-social bs-light bs-youtube ms-2 mb-2" href="#"><i class="ci-youtube"></i></a></div><img class="d-inline-block" src="assets/img/cards-alt.png" width="187" alt="Payment methods">
        </div>
      </div>
      <div class="pb-4 fs-xs text-light opacity-50 text-center text-md-start">© Toate drepturile rezervate. O solutie <a class="text-light" href="https://romdev.ro" target="_blank" rel="noopener">Romdev Stack SRL</a></div>
    </div>
  </div>
</footer>

<!-- Toolbar for handheld devices (Default)-->
<div class="handheld-toolbar">
  <div class="d-table table-layout-fixed w-100">
    <a class="d-table-cell handheld-toolbar-item" href="/wishlist"><span class="handheld-toolbar-icon"><i class="ci-heart"></i></span><span class="handheld-toolbar-label">Wishlist</span></a>
    <a class="d-table-cell handheld-toolbar-item" href="{{environment.siteBaseURL}}" ><span class="handheld-toolbar-icon"><i class="ci-store"></i></span><span class="handheld-toolbar-label">Spre magazin</span></a>
    <a *ngIf="!showMyAccountButton" class="d-table-cell handheld-toolbar-item" href="javascript:void(0)" data-bs-toggle="collapse" data-bs-target="#account-menu" onclick="window.scrollTo(0, 400)"><span class="handheld-toolbar-icon"><i class="ci-menu"></i></span><span class="handheld-toolbar-label">Meniu</span></a>
    <a *ngIf="showMyAccountButton" class="d-table-cell handheld-toolbar-item" href="/"><span class="handheld-toolbar-icon"><i class="ci-user"></i></span><span class="handheld-toolbar-label">Profilul tau</span></a>
    <a class="d-table-cell handheld-toolbar-item" id="cartFooterHandheld" href="/shopping-cart"><span class="handheld-toolbar-icon"><i class="ci-cart"></i><span class="badge bg-primary rounded-pill ms-1 count">0</span></span><span class="handheld-toolbar-label total">0 Lei</span></a>
  </div>
</div>
<!-- Back To Top Button--><a class="btn-scroll-top" href="#top" data-scroll><span class="btn-scroll-top-tooltip text-muted fs-sm me-2">Sus</span><i class="btn-scroll-top-icon ci-arrow-up">   </i></a>
