import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {HttpClient, HttpHeaders} from "@angular/common/http";

import {environment} from "../../environments/environment";
import {catchError, map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SessionStateService {


  constructor(private cookieService: CookieService, private http: HttpClient) { }

  notAuthenticated() {
    return this.cookieService.get("IdToken") === '';
  }

  private parseJwt(token: any) {
    try {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));


      return JSON.parse(jsonPayload);
    } catch (error) {
      //error parsing? redirect to login
      window.location.assign(environment.loginURL);
      return {};

    }

  }
  getIdToken() {
    return this.cookieService.get("IdToken");
  }


  signOut() {
    this.cookieService.delete("AccessToken");
    this.cookieService.delete("IdToken");
    this.cookieService.delete("cognito");
    setTimeout(() => {
      window.location.assign(environment.siteBaseURL);
    }, 0);
  }


  getUserData() {
    return this.parseJwt(this.getIdToken());
  }
}
