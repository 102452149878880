import {Routes} from '@angular/router';
import {environment} from "../environments/environment";
import {ProfileComponent} from "./account-profile/profile/profile.component";
import {ShippingAddressesComponent} from "./account-profile/shipping-adresses/shipping-addresses.component";
import {CheckoutComponent} from "./checkout/checkout.component";
import {ShoppingCartComponent} from "./shopping-cart/shopping-cart.component";
import {WishlistComponent} from "./wishlist/wishlist.component";


export const routes: Routes = [
  {path: '', component: ProfileComponent, title: "Profilul tau " + environment.name },
  {path: 'shipping-addresses', component: ShippingAddressesComponent, title: "Adrese livrare " + environment.name },
  {path: 'checkout', component: CheckoutComponent, title: "Checkout - " + environment.name },
  {path: 'shopping-cart', component: ShoppingCartComponent, title: "Cosul de cumparaturi - " + environment.name },
  {path: 'wishlist', component: WishlistComponent, title: "Wishlist - " + environment.name },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];
