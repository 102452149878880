import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SessionStateService} from "./session-state.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {

  constructor(private http: HttpClient, private sessionStateService:SessionStateService) { }

  getAccountAPIShippingAddressesURL() {
    return environment.accountApiBaseEndpoint + 'v1/addresses/shipping';
  }

  getAccountAPIWishlistURL() {
    return environment.accountApiBaseEndpoint + 'v1/wishlist';
  }

  getAccountShoppingCartURL() {
    return environment.accountApiBaseEndpoint + 'v1/shopping-cart';
  }

  getAuthHeaders() {
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + this.sessionStateService.getIdToken(),
      'Content-Type': 'application/json'
    });
    const options = {headers: headers};
    return options;
  }

  getShippingAddresses() {
    return this.http.get<any>(this.getAccountAPIShippingAddressesURL(), this.getAuthHeaders());
  }

  saveShippingAddresses(shippingAddresses:any) {
    return this.http.post<any>(this.getAccountAPIShippingAddressesURL(), JSON.stringify({'addresses':shippingAddresses}), this.getAuthHeaders());
  }


  getWishlist() {
    return this.http.get<any>(this.getAccountAPIWishlistURL(), this.getAuthHeaders());
  }

  saveWishlist(wishlist:any) {
    return this.http.post<any>(this.getAccountAPIWishlistURL(), JSON.stringify(wishlist), this.getAuthHeaders());
  }


  getShoppingCart() {
    return this.http.get<any>(this.getAccountShoppingCartURL(), this.getAuthHeaders());
  }

  overwriteShoppingCart(shoppingCart: any) {
    return this.http.post<any>(this.getAccountShoppingCartURL(), JSON.stringify(shoppingCart), this.getAuthHeaders());
  }
}
