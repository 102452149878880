import {Component, OnInit} from '@angular/core';
import {SessionStateService} from "../../service/session-state.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {CookieService} from "ngx-cookie-service";


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {

  constructor(private sessionStateService: SessionStateService, private modalService: NgbModal, private cookieService: CookieService) {
  }

  ngOnInit() {

    // Extract tokens from the URL fragment
    const hash = window.location.hash.substring(1); // Remove the leading '#' character
    const params = new URLSearchParams(hash);
    const idToken = params.get('id_token');
    const accessToken = params.get('access_token');

    if (idToken) {
      this.cookieService.set('IdToken', idToken, 1,'/', environment.cookieDomain);  // Store the id_token in a cookie
    }

    if (accessToken) {
      this.cookieService.set('AccessToken', accessToken, 1,'/', environment.cookieDomain);  // Store the access_token in a cookie
    }



    if(this.sessionStateService.notAuthenticated()) {
      window.location.assign(environment.loginURL);
    }
  }

  signOut() {
    this.sessionStateService.signOut();
  }

  protected readonly environment = environment;
}
