import {ErrorHandler, Injectable, Injector} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";
import {SessionStateService} from "./session-state.service";
import {environment} from "../../environments/environment";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MessageModalComponent} from "../common/modals/message-modal/message-modal.component";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private sessionStateService:SessionStateService, private injector: Injector) {
  }
  handleError(error: Error | HttpErrorResponse): void {
    if(!(error instanceof  Error)) {
      return;
    }
    // Log the error to the console.
    console.error(error);

    if (error instanceof HttpErrorResponse && error.status == 401) {
      window.location.assign(environment.loginURL);
      return;
    }

    if (error instanceof HttpErrorResponse && error.error.__type == 'NotAuthorizedException') {
      window.location.assign(environment.loginURL);
      return;
    }


    let message = 'An unexpected error occurred';


    if(error instanceof HttpErrorResponse && error.error && error.error.message) {
        message = error.error.message;
    }

    // Display a friendly error message to the user.
    let messageModal = this.injector.get(NgbModal).open(MessageModalComponent);
    messageModal.componentInstance.modalTitle = 'Eroare';
    messageModal.componentInstance.message = message;

    return;
  }
}
